import React, { useEffect } from 'react';

const Admin = () => {

  useEffect(() => {
    window.location.href = 'https://wordpress-250338-2703165.cloudwaysapps.com/wp-admin/';
  },[]);

  return (
    <>
      <div className="home">
      </div>
    </>
   );
}

export default Admin;